import { Button, Select } from 'antd';
import logo from "../../assets/images/logo_horizontal_white.svg";
import css from './Login.module.scss'
import GoogleIcon from "../../assets/images/google.png";
import FacebookIcon from "../../assets/images/facebook.png";

const { Option } = Select;

function Login() {
    return (
        <div className={css.wrapper}>
            <div className={css.logo}>
                <img src={logo} alt={''}/>
            </div>

            <div className={css.dialog}>
                <h3>Login to </h3>
                <h2>RFMS Admin Console</h2>
                <p>Roles: SA, AF, SM</p>

                <div>
                    <Button
                        style={{width: 220}}
                        href={`/auth/google`}
                        icon={<img src={GoogleIcon} style={{ width: 18, marginRight: 10 }}/>}>
                        Sign in with Google
                    </Button>
                    {/*<br/>  <br/>*/}
                    {/*<Button*/}
                    {/*    style={{width: 220}}*/}
                    {/*    href={`/auth/facebook`}*/}
                    {/*    icon={<img src={FacebookIcon} style={{ width: 18, marginRight: 10 }}/>}>*/}
                    {/*    Sign in with Facebook*/}
                    {/*</Button>*/}
                </div>
            </div>
        </div>
    );
}

export default Login
