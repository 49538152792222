import { action, makeAutoObservable } from 'mobx';
import { apiClient } from "../utils/api";
import { IUser } from "../intarfaces/IUsersResponse";

class CommonStore {

  adminUser?: IUser;

  constructor() {
    makeAutoObservable(this)
  };


  @action.bound
  fetchAdminUser = async () => {
    this.adminUser = await apiClient.getAdminUser();
  }


}

export default new CommonStore();
