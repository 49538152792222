import React, { useEffect } from 'react';
import { Observer } from "mobx-react-lite";
import { Button, DatePicker, Divider, Select } from 'antd';
import paymentsStore from '../../stores/paymentsStore';
import moment from 'moment';
import css from "./Payments.module.scss"
import { Loader } from "../../components/loader/Loader";
import { PaymentsTable } from "./PaymentsTable";
import { useHistory, useLocation } from "react-router-dom";
import { formatAmount } from "../../utils";
import { Chart } from "./PaymentsChart";
import { newTempAchiement } from "../../constants/Achievements";
import { accessTokenPath } from "../../constants/common";

const Option = Select.Option;
const {RangePicker} = DatePicker;

const Payments = () => {
  const store = paymentsStore;
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    store.setFilterByEventId(params.get('eventId') || undefined);
    store.setFilterByUserId(params.get('userId') || undefined);
    store.setFilterByStatus(params.get('status') || undefined);
    store.fetchPayments();
    store.fetchEvents();
    // eslint-disable-next-line
  }, []);

  const handleChangeEventId = (eventId: string) => {
    history.push({search: eventId ? `?eventId=${eventId}` : ''});
    store.setFilterByEventId(eventId);
    store.fetchPayments();
  }

  // const handleChangeStatus = (status: string) => {
  //   store.setFilterByStatus(status);
  //   store.fetchPayments();
  // }

  return (
    <Observer>
      {() => (
        <div>
          <div className="paginateBlock">
            <div>
              <strong>{formatAmount(store.data ? store.data.totalAmount : 0, 'USD')}</strong>
              {' '} / {' '}
              <strong className="accent">{formatAmount(store.data ? store.data.feeAmount : 0, 'USD')}</strong>
            </div>

            <Divider type="vertical"/>

            <div className={css.filtersContainer}>
              <Select className={css.select} placeholder='All active fundraisers'
                      value={store.filter.eventId}
                      onChange={handleChangeEventId}
                      allowClear>
                {store.events.map(event =>
                  (<Option value={`${event.id}`} key={event.id}>{event.name}</Option>)
                )}
              </Select>

            </div>

            <Divider type="vertical"/>

            {/*<div className={css.filtersContainer}>*/}
            {/*  <Select className={css.select} placeholder='All statuses'*/}
            {/*          value={store.filter.status}*/}
            {/*          onChange={handleChangeStatus}*/}
            {/*          allowClear>*/}
            {/*    <Option value='succeeded' >succeeded</Option>*/}
            {/*    <Option value='refunded' >refunded</Option>*/}
            {/*    <Option value='disputed' >disputed</Option>*/}
            {/*    <Option value='dispute_accepted' >dispute_accepted</Option>*/}
            {/*  </Select>*/}

            {/*</div>*/}

            {/*<Divider type="vertical"/>*/}

            <RangePicker format={'MM/DD/YYYY'}
                         defaultValue={[moment(store.filter.dateFrom, 'YYYY-MM-DD'), moment()]}
                         allowClear={false} onCalendarChange={store.onIntervalChange}/>

            <Divider type="vertical"/>

            {store.data && <Chart days={store.data.days}/>}

            <Divider type="vertical"/>

            <Button
              type="default"
              target="_blank"
              href={`/api/v1/admin/payments.csv?accessToken=${localStorage.getItem(accessTokenPath)}&dateFrom=${moment(store.filter.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD')}&dateTo=${moment(store.filter.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD')}`}>
              Download CSV
            </Button>

          </div>

          {store.isLoading ?
            <Loader/> :
            <div className="table-responsive">
              {store.data ?
                <PaymentsTable donations={store.data.donations}
                               onBeneficiaryChange={store.changeBeneficiary}/> : ''
              }
            </div>
          }

          <Button
            className="btnAdd"
            onClick={store.fetchMore}
            hidden={!store.isMoreAvailable}
            loading={store.isLoadingMore}
            disabled={store.isLoadingMore}>
            <span>Show more donations</span>
          </Button>
        </div>
      )}
    </Observer>
  )
}


export default Payments;
