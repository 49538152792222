import React from 'react';
import { Popconfirm } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom";

import { IUser } from '../../intarfaces/IUsersResponse';
import { navigation, navigationAF, navigationSM } from '../../constants/Navigations';
import { accessTokenPath } from '../../constants/common';

import logo from "../../assets/images/logo_horizontal_white.svg";
import css from "./NavBar.module.scss"


interface IProps {
  authUser: IUser;
};

export function NavBar(props: IProps) {
  const { email } = props.authUser;
  let userNavigation: any[] = [];
  if (props.authUser) {
    if (props.authUser.role === 'SA') userNavigation = navigation;
    if (props.authUser.role === 'SM') userNavigation = navigationSM;
    if (props.authUser.role === 'AF') userNavigation = navigationAF;
  }

  const confirmLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

  return (
    <div className={css.navBarContainer}>
      <img className={css.logo} src={logo} alt="logo"/>

      <div className={css.navContainer}>
        <div className={css.navButtons}>
          {userNavigation.map((item, id) => (
            <NavLink to={item.path} key={id} className={css.link} activeClassName={css.active}>
              {item.name}
            </NavLink>
          ))}
        </div>

        <Popconfirm
          className={css.logingContainer}
          title="Do you want to logout?"
          onConfirm={confirmLogout}>
          <div className={css.logout}>
            {email}
            {email && <CaretDownOutlined className={css.caret}/>}
          </div>
        </Popconfirm>
      </div>
    </div>
  );
}
