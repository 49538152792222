import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";

import { NavBar } from "./components/navBar/NavBar";
import Users from "./screens/users/Users";
import Fundraisers from './screens/fundraisers/Fundraisers';
import Payments from "./screens/payments/Payments";
import Achievements from "./screens/achievements/Achievements";
import BookLists from "./screens/booksLists/BookLists";
import Books from "./screens/booksLists/books/Books"
import Config from "./screens/config/Config";
import { URLS } from './constants/urls';
import { accessTokenName, accessTokenPath } from './constants/common';

import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import './App.scss';
import commonStore from "./stores/commonStore";
import { observer } from "mobx-react-lite";
import Login from "./screens/login/Login";


export const history = createBrowserHistory();
const url = new URL(window.location.href);
if (url.searchParams.get(accessTokenName)) {
  localStorage.setItem(accessTokenPath, url.searchParams.get(accessTokenName) || "")
}
;

const App = observer(() => {
  const { adminUser } = commonStore;

  useEffect(() => {
    commonStore.fetchAdminUser();
  }, [])

  return (
    <div className="contentWrapper">
      <BrowserRouter>
        {adminUser && <NavBar authUser={adminUser}/>}
        <Switch>
          <Route exact path={URLS.USERS} component={Users}/>
          <Route exact path={URLS.FUNDRAISERS} component={Fundraisers}/>
          <Route exact path={URLS.PAYMENTS} component={Payments}/>
          <Route exact path={URLS.ACHIEVEMENTS} component={Achievements}/>
          <Route exact path={URLS.BOOKLISTS} component={BookLists}/>
          <Route exact path={`${URLS.BOOKLISTS}/:id/books`} component={Books}/>
          <Route exact path={`${URLS.BOOKLISTS}/:id/add`} component={Books}/>
          <Route exact path={URLS.CONFIG} component={Config}/>
          <Route exact path={URLS.LOGIN} component={Login}/>

          <Redirect to={URLS.FUNDRAISERS}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
})

export default App;
